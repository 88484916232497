@import '~@angular/material/_theming';
@import 'theme.scss';

$primary-palette: mat-palette($md-primary, 500);
$accent-palette: mat-palette($md-accent, 500);
$warn-palette: mat-palette($mat-red);
$white-palette: mat-palette($mat-grey, A100);

$primary: mat-color($primary-palette);
$accent: mat-color($accent-palette);
$warn: mat-color($warn-palette);
$gray: rgba(0, 0, 0, 0.12);

$custom-typography: mat-typography-config(
  $font-family: 'Montserrat, sans-serif'
);
@include mat-core($custom-typography);

.accent-color {
    color: $accent; }

.warn-color {
    color: $warn; }

$colorWhite: mat-color($white-palette);
$colorBlack: #141414;
$colorGrey: #5b5f64;
$colorGreyLight: #f2f4f5;

// Colors global optimization when starting theme
$colorMenu: $colorGrey;
$colorActivies: $primary;
$colorPackagesBg: $colorBlack;
$colorPackagesCardHover: $accent;
$colorFooterButton: $accent;
$colorFooterBg: $primary;

