// @import url("https://fonts.googleapis.com/css?family=Montserrat:400,700,800,900");

@font-face {
    font-family: "Real Head W01";
    font-weight: 300;
    src: url(fonts/site/rhw300.eot?#iefix) format("eot");
    src: url(fonts/site/rhw300.woff2) format("woff2");
    src: url(fonts/site/rhw300.woff) format("woff");
    src: url(fonts/site/rhw300.ttf) format("truetype");
}

@font-face {
    font-family: "Real Head W01";
    src: url(fonts/site/rhw400.eot?#iefix) format("eot");
    src: url(fonts/site/rhw400.woff2) format("woff2");
    src: url(fonts/site/rhw400.woff) format("woff");
    src: url(fonts/site/rhw400.ttf) format("truetype");
    font-weight: 400;
}

@font-face {
    font-family: "Real Head W01";
    src: url(fonts/site/rhw600.eot?#iefix) format("eot");
    src: url(fonts/site/rhw600.woff2) format("woff2");
    src: url(fonts/site/rhw600.woff) format("woff");
    src: url(fonts/site/rhw600.ttf) format("truetype");
    font-weight: 600;
}

@font-face {
    font-family: "Real Head W01";
    src: url(fonts/site/rhw700.eot?#iefix) format("eot");
    src: url(fonts/site/rhw700.woff2) format("woff2");
    src: url(fonts/site/rhw700.woff) format("woff");
    src: url(fonts/site/rhw700.ttf) format("truetype");
    font-weight: 700;
}

* {
    font-family: 'Real Head W01', sans-serif;
}

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: url(fonts/MaterialIcons-Regular.eot);
    src: local("Material Icons");
    src: local("MaterialIcons-Regular");
    src: url(fonts/MaterialIcons-Regular.woff2) format("woff2");
    src: url(fonts/MaterialIcons-Regular.woff) format("woff");
    src: url(fonts/MaterialIcons-Regular.ttf) format("truetype");
}

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: 'liga';
}
